import * as React from "react";
import BreadCrumbs from "../../components/BreadCrumbs";
import DevelopmentProcess from "../../components/DevProcess";
import FilterPortfolio from "../../components/FilterPortfolio";
import Layout from "../../components/layout";
import WhyChooseUs from "../../components/WhyChoose";
import siteSettings from "../../constants/siteSettings";

// markup
const Index: React.FC = () => {
  return (
    <Layout pageTitle="Mobile App Development - Light Infotech Software Solutions">
      <main id="main">
        <BreadCrumbs visitPageTitle={"Mobile App Development"} />
        <section className="values">
          <div className="container" data-aos="fade-up">
            <header className="section-header">
              <h2>{siteSettings.mobileAppDev.title}</h2>
              <p className="mt-4" style={{ lineHeight: 1.5 }}>
                {siteSettings.mobileAppDev.description}
              </p>
            </header>
            <div className="row">
              {siteSettings.mobileAppDev.workDone.map((item, index) => (
                <div className="col-md-3 mt-5">
                  <div className="row">
                    <div
                      dangerouslySetInnerHTML={{ __html: item.icon }}
                      className="col-4 d-flex justify-content-center align-items-center"
                    ></div>
                    <div className="col-8">
                      <span className="workDoneNumber">{item.number}+</span>
                      <span className="workDoneTitle">{item.title}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <WhyChooseUs />
        <section className="values">
          <div className="container" data-aos="fade-up">
            <header className="section-header">
              <h2>{siteSettings.mobileAppDev.serviceTitle}</h2>
              <p className="mt-4" style={{ lineHeight: 1.5 }}>
                {siteSettings.mobileAppDev.servicesLine}
              </p>
            </header>
            <div className="row">
              {siteSettings.mobileAppDev.servicesWeProvide.map(
                (item, index) => (
                  <div key={index} className="col-lg-4">
                    <div className="box">
                      <h5 className="mb-0">{item}</h5>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </section>
        <DevelopmentProcess />
        <FilterPortfolio
          title={siteSettings.mobileAppDev.portfolioTitle}
          tagline={siteSettings.mobileAppDev.portfolioLine}
          type="Mobile App"
        />
      </main>
    </Layout>
  );
};

export default Index;
